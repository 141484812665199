@media only screen and (max-width: 1500px) {

	#top { display: none; }
	#social-dock { top: 100px; right: 5%; }

	#news-social-sec { box-shadow: inset 80px 0 0 0 #8c183b; }
	footer { box-shadow: inset 80px 0 0 0 #fff, inset -20px 0 0 0 rgba(0,0,0,.1); }
	footer:before { z-index: -1; opacity: .3; }

} // end of 1500px

@media only screen and (max-width: 990px) {

	#search input[type="search"] {font-size: 20px;}

	#social-dock { top: 70px; }

	// Header
	header{height: auto; overflow: hidden;
		&:before { display: none; }
		.wrapper{padding: 0;}
	}

	#logo-index  {margin: 120px auto 20px; float: none; display: block; max-width: 85%;
		a { text-align: center; max-width: 90%; margin: 0 auto;
			span { text-align: center; max-width: 90%; text-indent: 0; margin: 10px auto; }
			img#logo{ float: none; display: block; margin: 0 auto 30px;}
		}
	}

	#welcome-sec { width: auto; max-width: 95%; padding: 80px 0 120px; }
	#welcome-sec p { width: auto; }
	#welcome-sec .read-more { bottom: -115px; }
	#welcome-sec:before { display: none; }

	#news-social-sec #SideHeadingNews,#news-social-sec .tw-cont { height: auto; width: auto; float: none; display: block; padding: 0; }

	#news-social-sec { box-shadow: none; padding: 50px 10px; margin: 0 auto; }
	#news-social-sec:before { width: 100%; }

	#SideHeadingNews h2 { font-size: 40px; text-align: center;
		span { font-size: 40px; text-align: center; }
	}

	#SideHeadingNews ul.SideList li { width: 100%; max-width: 400px; margin: 0 auto 30px; }

	#news-social-sec .tw-cont .tw { color: #fff; text-indent: 0; text-align: center; margin: 0 auto 30px; font-size: 30px; }
	#news-social-sec .tw-cont .tw:after { display: none; }

	#SideHeadingNews .wrap { position: relative; left: auto; right: auto; margin: 0 auto; width: 100%; 
		a { display: block; background: #8c183b; height: 50px; line-height: 50px; margin: 10px auto!important; max-width: 400px; }
	}

	.tw-cont { max-width: 500px; margin: 60px auto; }
	.twitter-timeline { width: 100%!important; margin: 0 auto!important; }

	#news-social-sec .tw-cont .tw-more { display: none; }
	#news-social-sec:after { display: none; }

	footer .img { display: none; }
	
	footer { box-shadow: none; }
	footer .wrapper:before { display: none; }
// -----------------------------------//
// GENERAL STYLES
// -----------------------------------//

	// Body
	body {min-width: 250px;	overflow-x: hidden;}

	h1 {font-size: 2em;}

	// Wrappers
	.wrapper {width: 100%;}

	// Mainholder
	#mainholder{width: 100%;padding: 0;}

	// Contents
	.Content1Column,
	.Content2Column,
	.Content3Column{width: 90%; padding: 30px; margin: 5% auto; float: none; display: block;}

	// Col Left and Right
	.ColumnLeft,
	.ColumnRight{width: 90%; padding: 30px; margin: 5% auto; float: none; display: block;}

	//Home page alert
	.modal-home{
		.message-modal{width: 90%; margin: 0 5%; left: 0; top: 20%; padding: 0;
			.modal-content{
				.modal-text{padding: 3%;}
				a.close-modal{position: absolute; top: 3%; right: 3%; background: #fff; padding: 3px; border-radius: 0px 0px 0px 7px;}
			}
		}
	}

	// Global
	img {max-width: 100%;height: auto;}
	table {max-width: 100%;}

	//Responsive tables
	table.tableborder{width: 100%; overflow: scroll; white-space: nowrap; table-layout: fixed;position: relative;
		&:before{content: "";width: 30px;height: 100%;position: absolute;top: 0;right: -1px;
			background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(0,0,0,0.2) 100%);
			background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.2) 100%);
			background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.2) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#33000000',GradientType=1 );
		}
		tbody{width: 100%; float: left; white-space: normal; overflow-x: scroll;}
	}

	// Calendar
	#calendar {
			.fc-header td {display: block;width: 100%;}
			.fc-button-content {font-family: Arial;}
			.fc-header-right {text-align: left;}
			.fc-header-center {text-align: left;}
	}

	// Forms
	form div input[type="text"], form div textarea, form span textarea, form div input[type="textarea"], form div input[type="password"] {width: 200px;}
	form div span.button {padding-left: 0;}

	// -----------------------------------//
	// FOOTER
	// -----------------------------------//

	footer{height: auto; padding: 30px 0;;
		.wrapper{width: 90%;padding: 5%;
			h2, p, a {text-align: center; margin: 10px auto; display: block; float: none;
				&:before {display: none;}
			}
			a {word-break: break-all;}
		}
	}

	h2.footer-heading { font-size: 30px; line-height: 1; margin: 0 auto 40px!important; }

	#awards {display: none;}
		.sub-footer  {height: auto; line-height: normal; padding: 20px 0;
			.wrapper { padding: 0 10%; }
			#copyright {text-align: center; margin: 8px auto; float: none;}
			#credit {float: none;}
	}

} // end of 990px

// -----------------------------------//
// MOBILE
// -----------------------------------//

@media only screen and (max-width: 600px) {

	// slider reduced for mobile inside pages
	#slider {height: 400px!important; min-height: 400px!important;}
	.body_7887 #slider{min-height: 800px!important;}

// to give overlay to mobile Slider
	.backstretch {position: relative;
  	 	&:before {position: absolute;content: ''; background: rgba(0,0,0,.3); left: 0px;top:0px; width:100%; height: 100%; opacity: .5; z-index: -1;}
    img  {top: 0px !important;}
	}

} // end of 600px

@media only screen and (max-width: 650px) and (orientation:landscape) {
	.body_7887 #slider {min-height: 150vh!important; height: 150vh!important;}
}

// -----------------------------------//
// BROWSER SPECIFIC
// -----------------------------------//

// IE fixes
@media all and (-ms-high-contrast: none), 
       all and (-ms-high-contrast: active),
       screen\0, screen\9 {

} // end of internet explorer
