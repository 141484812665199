$nav-link-bg-hov: #ad7c4d;
$nav-dropdown-hov: #c9915d;

// -----------------------------------//
// NAVIGATION WRAPPERS
// -----------------------------------//

nav#navholder { z-index: 400; width: 990px; @extend .clearfix; position: absolute; left: 0; right: 0; margin: 0 auto; top: 8px;
    > .wrapper {width: 990px; margin: 0 auto;}
}

// -----------------------------------//
// MAIN NAVIGATION
// -----------------------------------//

ul.nav {
	display: table; width: 990px; margin: 0; list-style: none;

	// Top Level List
	li { margin: 0;  background: none; position: relative; display: table-cell; text-align: center; white-space: nowrap;padding: 0;
	    a { height: 68px; line-height: 68px; display: block; text-align: center;font-size: 15px;color: #fff; font-weight: bold; }
	}
	> li:hover > a, > li > a:hover { color: #fff; text-decoration: none; box-shadow: inset 0 -2px 0 0 #c9915d;}

	// 2nd Level List
	li {
		ul {position: absolute; left: -999em; margin: 0; padding: 0 20px 20px; background: none; opacity: 0;visibility: hidden;transition: (all 0.3s ease-in-out);}
		&:hover ul {left: auto; margin-left: -20px; opacity: 1;visibility: visible;transition: (all 0.3s ease-in-out);}
		&:active ul{transition: (opacity 0.1s ease-in-out);}
	}

	// 2nd Level List Items
	li li { float: none; display: block; padding: 0; height: auto; line-height: 35px;

		a {background: $nav-link-bg-hov; height: 39px; line-height: 28px; padding: 6px 12px; min-width: 170px; float: none; top: auto; display: block; position: static; text-align: left; text-decoration: none;font-size: 15px;text-transform: none; font-weight: normal; }
		
		&:hover, &:hover > a, a:hover, a:focus {background: $nav-dropdown-hov;}
	}

	// Prepend 'more' arrow to parent list items
	li li a.parent:after {margin: -1px 0 0 10px; content: url('/i/design/lite/nav-right-arrow-white.png'); opacity: 1; line-height: 1;}

	// 3rd Level List
	ul ul {z-index: 505;}
	li:hover ul ul {left: 100%; top: 0; opacity: 0;visibility: hidden;transition: (all 0.3s ease-in-out);}
	ul li:hover ul {left: 100%; top: 0; opacity: 1;visibility: visible;transition: (all 0.3s ease-in-out);}

}

// -----------------------------------//
// NAVIGATION DOCKED ON SCROLL
// -----------------------------------//

// BACK TO TOP BTN
#top {position: absolute; top: -10em; display: block; margin: 0; font-size: 18px; color: #c9915e; z-index: 999; transition: 1s; text-decoration: none; }

.visible-now {position: fixed!important; top: 12px!important;  right: 50px;}

// FIXED NAV
.fixed {
	position: fixed!important;
	animation: move-down 1s ease forwards;
	background: #222;
	width: 100%!important;
	height: 50px;
	left: 0; right: 0; margin: 0 auto;

	ul.nav li a { height: 50px; line-height: 50px; }
	ul.nav li li a { height: 39px; line-height: 28px; }
  
	@keyframes move-down {
	  from {top: -50px; opacity: 0;}
	  to {top: 0; opacity: 1;}
	}
 }